html,body{
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
*{
  box-sizing: border-box;
}
body {
  background: #191a1e;
  overflow-x: hidden;
  
}

