

/* Typography classes for headings and paragraphs texts */

/* This is a general stylesheet. Contains font styling and color css */

/* Component specific styles will be found in the respective component directory */

*{
   font-family: 'Poppins', sans-serif;
}

.vertical-text-break{
  display: flex;
  flex-direction: column;
}

.color-dark{
  color: #7A52E9;
}

.color-white{
  color:white;
}
.color-black{
  color: black;
}

.font-size-small{
  font-size: small;
}

.text-highlight{
  background: #7A52E9;
  padding: 0 5px;
  margin: 0px 2px;
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.dim-text{
  color: #ffffff60;
}

.medium-font{
  font-weight: 500;
}

.bold{
  font-weight: 700;
}

/* section heading style */

.section-heading{
  font-size: 15px;
  font-weight: 500;
}

.light-theme .section-heading::after,.light-theme .detailed-nav-link::after{
  background: black;
}

.section-heading::after,.detailed-nav-link::after{
  content: '';
  width: 20px;
  height: 2px;
  background: white;
  display: block;
  margin-top: 3px;
}

.lg-line-height{
  line-height: 1.3em;
}

.xl-line-height{
  line-height: 1.7em;
}

/* --- */


/* Smartphones (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {

  h1{
    font-size: 42px;
  }

  p{
    font-size: 14px;
  }


}

/* Smartphones (landscape) ----------- */
@media only screen
and (min-width : 321px) {
 
}
 
/* Smartphones (portrait) ----------- */
@media only screen
and (max-width : 320px) {
 
}

/* iPads (portrait and landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px) {
 
}
 
/* iPads (landscape) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
 
}
 
/* iPads (portrait) ----------- */
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
 
}

/* Desktops and laptops ----------- */

/* this covers all, for large and medium size use another. */


/* Desktops and laptops ----------- */
@media only screen
and (min-width : 1224px) {
  h1{
    font-size: 72px;
  }

  p{
    font-size: 15px;
    line-height: 1.5em;
  }

  h3{
    font-size: 18px;
    letter-spacing: 0.5px;
  }
  a{
    font-size: 15px;
  }

}


/* Large screens ----------- */
@media only screen
and (min-width : 1659px) {
  h1{
    font-size: 96px;
  }

  h2{
    font-size: 36px;
  }
}

@keyframes fadeInText{
  from{
    clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
  }

  to{
    clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 100% 0%);
  }
}

.fadeInText span{
  animation-name: fadeInText;
  animation-duration: 1s;
  animation-delay: 0.3s;
  animation-iteration-count: once;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}

@keyframes weirdAnimation{
  0%{
    clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 0% 0%);
  }
  20%{
    clip-path: polygon(0% 0%, 0% 100%, 1.81% 122.46%, 1.49% -49.44%);
  }
  40%{
    clip-path: polygon(0% 0%, 0% 100%, 5.68% 158.42%, 7.16% -62.92%);
  }
  60%{
    clip-path: polygon(0% 0%, 0% 100%, 8.45% 153.93%, 7.16% -62.92%);
  }
  100%{
    clip-path: polygon(0% 0%, 0% 100%, 11.49% 140.45%, 12.21% -40.45%);
  }
  
}

.weirdAnimation{
  animation-name: weirdAnimation;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.detailed-nav-link a{
  color: black;
  font-weight: bold;
}

.detailed-nav-link p{
  margin: 0;
}

.detailed-nav-link a>p:nth-child(1){
  font-size: 32px;
  margin: 0;
}

.detailed-nav-link::after{
  content: '';

}