#nav-logo a{
    font-weight: bold;
    font-size: 16px;
    color: white;
}

#navbar-container{
    position: fixed;
    top: 60px;
    width: 76%;
    mix-blend-mode: difference;
    z-index: 2;
}

.menu-links{
    color: white;
    font-size: 72px;
    font-weight: 700;
    transition: all 0.2s ease-in-out;
    
}

.menu-links:hover{
    color: #abc629e0;
}

#menu{
    position: fixed;
    top: 0;
    z-index: 3;
    /* display: flex; */
    flex-direction: column;
    justify-content: space-evenly;
    height: 100vh;
}

.menu-open{
    display: flex;
}

.menu-closed{
    display: none;
}

#nav-links-container{
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#nav-links-container > div{
    padding: 1em 0;
}

#menu ul a{
    
    color: white;
}

#menu ul a:hover{
    color: #abc629e0;
}

.active-navlink{
    color: #abc629e0;
}