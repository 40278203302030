

/* Layout and color classes used throughout the project */

/* These are general layout styles. Component specific styles will NOT be found here. Mainly helper classes like d-flex, h-100 etc etc. */

ul {
	list-style: none;
	padding-left: 0;
}
a{
  text-decoration: none;
}
img{
  max-width: 100%;
}
.d-flex{
    display: flex;
  }

.d-none{
  display: none;
}

 .row{
   display: flex;
   flex-wrap: wrap;
 } 

  .col-1{
    width: 25%;
  }
  .col-2{
    width: 50%;
  }
  .col-3{
    width: 75%;
  }
  header{
    height: 100vh;
  }
  .justify-content-center{
    justify-content: center;
  }

  .align-items-center{
    align-items: center;
  }
  .align-items-baseline{
    align-items: baseline;
  }

.justify-content-space-between{
    justify-content: space-between;
  }
  .position-relative{
    position: relative;
  }
  /* offset classes */
  .col-offset-1{
    margin-left: 25%;
  }
  
  .col-offset-2{
    margin-left: 50%;
  }
  
  .col-offset-3{
    margin-left: 75%;
  }
  .tech-image-grid div{
    min-width: 100px;
    min-height: 120px;
    margin-right: 100px;
    display: flex;
    align-items: center;
  }

  @media (min-width: 320px) and (max-width: 480px) {

    .tech-image-grid{
      justify-content: space-between;
    }
    .tech-image-grid div{
      min-width: 50%;
      margin-right: 0;
    }

    .d-m-none{
      display: none;
    }

    .m-flex-direction-col-reverse{
      flex-direction: column-reverse!important;
    }
     
    /* width classes for mobile */
    .col-m-1{
      width: 25%;
    }
    .col-m-2{
      width: 50%;
    }
    .col-m-3{
      width: 75%;
    }

    .col-m-4{
      width: 100%;
    }

    /* offset classes for mobile*/
    .col-m-offset-0{
      margin-left: 0;
    }
  
    .col-m-offset-1{
      margin-left: 25%;
    }
    
    .col-m-offset-2{
      margin-left: 50%;
    }
    
    .col-m-offset-3{
      margin-left: 75%;
    }
    
  }

  @media (min-width:1440px){
    .py-lg-5{
      padding-top:5em;
      padding-bottom: 5em;
    }

    .my-lg-100px{
      margin-top: 100px;
      margin-bottom: 100px;
    }
    .mb-lg-100px{
      margin-bottom: 100px!important;
    }
  }

.h-100{
    height: 100%;
  }

.hv-100{
  height: 100vh;
}

.w-100{
    width: 100%;
}

.w-75{
  width: 75%;
}

.my-5{
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}

.py-4{
  padding-top: 1em;
  padding-bottom: 1em;
}

.py-5{
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.mx-5{
  margin-right: 1.5em;
  margin-left: 1.5em; 
}

.mr-5{
  margin-right: 1.5em;
}

.mt-5{
  margin-top: 1.5em;
}

.mb-5{
  margin-bottom: 1.5em;
}

.mb-100px{
  margin-bottom: 100px;
}

.mb-200px{
  margin-bottom: 200px;
}

.bg-white{
  /* background: white; */
  position: relative;
}

.bg-white::before{
  content: '';
  width: 140%;
  height: 100%;
  background: white;
  position: absolute;
  left: -15%;
  z-index: -1;
  top: 0;
}
  /* theme and color settings */

  .light-theme{
    color: black;
      
  }

  .light-theme a{
    color: black;
  }

  .dark-theme{
    color: white; 
  }

  .dark-theme a{
    color: white;
  }

  .pointer{
    cursor: pointer;
  }

  main{
    margin: 0 12%;
  }


  header{
    padding-top: 55px;
    
  }

  .flex-column{
    flex-direction: column;
  }

  .pr-2{
    padding-right: 2em;
  }

  

  .active-left-mask{
    position: relative;
  }

  .active-left-mask::before{
    content: '';
    background: #191a1e;
    position: absolute;
    width: 100%;
    height: 100%;
    left: -100%;
    z-index: 1;
  }