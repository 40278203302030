#background{
  /* background: #191A1E; */
  width: 100%;
  height: 100%;
  max-width: 100vw; /* this was added to adjust for the mobile width */
  
  position: fixed;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  /* z-index: -1; */
  box-sizing: border-box;
}

.close-curtain{
  z-index: -1;
  /* z-index: 2; */
}

.open-curtain{
  z-index: 2;
}

.container{
  position: relative;
  height: 100%;
  width: 76%;
  /* margin-left: -1px; */
  display: flex;
  box-sizing: border-box;
}



.curtain{
  position: relative;
  height: 100%;
  border-left: 1px solid rgba(101, 101, 101, 0.2); 
  width: 25%; 
  /* margin-left: -4px; */
  box-sizing: border-box;
}


.left-border,.right-border{
  position: relative;
  border-left: 1px solid rgba(101, 101, 101, 0.2); 
  width: 12%;
  height: 100%;
  box-sizing: border-box;
}

.curtain::before,.left-border::before,.right-border::before{
  content: '';
  position: absolute;
  height: 100%;
  /* width: 0%; */
  background:white;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  box-sizing: border-box;
  z-index: 2;
}

.close-curtain .left-border::before,.close-curtain .right-border::before,.close-curtain .curtain::before{
  width: 0%;
}

.open-curtain .left-border::before,.open-curtain .right-border::before,.open-curtain .curtain::before{
  width: 100%;
}